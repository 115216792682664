import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
import Div100vh, { use100vh } from "react-div-100vh"
import { Animate } from "react-move"
import Zoom from "react-reveal-iframe/Zoom"
import { useMediaQuery } from "react-responsive"
import YTVideo from "./ytvideo"
import VimeoVideo from "./vimeovideo"
import openCase from "../images/opencase.png"
import placeholder from "../images/transpranetplaceholder.png"

const Case = ({
  data,
  caseClassName,
  startBottom,
  startLeft,
  shelfHeight,
  ninetyFiveVWWidth,
}) => {
  const [frame, setFrame] = useState(false)
  const [finalFrame, setFinalFrame] = useState(false)
  const [video, setVideo] = useState(false)
  const [animation, setAnimation] = useState(false)
  const [animation2, setAnimation2] = useState(false)
  const [open, setOpen] = useState(false)
  const [visability, setVisability] = useState(true)
  const [animationSrc, setAnimationSrc] = useState(`${placeholder}`)
  const [animation2Src, setAnimation2Src] = useState(`${placeholder}`)
  const [viewportHeight, setViewportHeight] = useState(0)
  const calculatedViewportHeight = use100vh()
  useLayoutEffect(() => {
    setViewportHeight(calculatedViewportHeight)
  })

  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  })

  const destroyTimeouts = () => {
    const highestTimeoutId = setTimeout(";")
    for (let i = 0; i < highestTimeoutId; i += 1) {
      clearTimeout(i)
    }
  }
  const destroy = () => {
    setOpen(false)
    setAnimationSrc(`${placeholder}`)
    setAnimation2Src(`${placeholder}`)
    setVideo(false)
    setFrame(false)
    setFinalFrame(false)
    setAnimation(false)
    setAnimation2(false)
    setVisability(true)
  }

  const theVideo = () => {
    if (data.ytcode == null || data.ytcode === "") {
      return (
        <VimeoVideo
          className={`${open && video && animation2 ? "block" : "hidden"}`}
          code={data.vimeocode}
        />
      )
    }
    return (
      <YTVideo
        className={`${open && video && animation2 ? "block" : "hidden"}`}
        code={data.ytcode}
      />
    )
  }

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }

  const whatToDisplay = () => {
    if (finalFrame) {
      return (
        <img
          style={isMobile ? { width: "95vw" } : { height: "707px" }}
          src={data.finalframe.asset.url}
          className={`opacity-100 max-w-none${
            !isMobile ? " contrast-[0.95]" : ""
          }`}
          alt=""
          draggable="false"
        />
      )
    }
    if (animation2) {
      return (
        <img
          style={isMobile ? { width: "95vw" } : { height: "707px" }}
          src={animation2Src}
          className="opacity-100 transition duration-300 max-w-none"
          alt=""
          draggable="false"
          id="animation2"
        />
      )
    }
    if (frame) {
      return (
        <div className="relative">
          <button
            className={`relative block ${caseClassName}`}
            style={{ pointerEvents: "auto" }}
            className="opacity-100"
            onClick={() => {
              setAnimation2(open)
              timeout(1).then(() => {
                setAnimation2Src(`${data.animation2.asset.url}`)
              })
              timeout(1501).then(() => setVideo(open))
              timeout(2671).then(() => setFinalFrame(open))
            }}
            type="button"
          >
            <img
              style={isMobile ? { width: "95vw" } : { height: "707px" }}
              src={data.coverframe.asset.url}
              alt=""
              draggable="false"
              className="max-w-none"
            />
            <div
              className="dillon-hover z-50 absolute inset-0"
              style={{
                margin: "23.33% 29.479% 24% 32.1875%",
              }}
            />
          </button>
          {/* <h3 className="text-white text-lg sm:text-xl text-center absolute bottom-4 sm:bottom-16 w-full">
            {`${isMobile ? "Tap" : "Click"} to Watch Video`}
          </h3> */}
          <div className="w-full absolute bottom-4 sm:bottom-16 flex items-center justify-center">
            <img className="h-14" src={openCase} draggable="false" />
          </div>
        </div>
      )
    }
    if (animation) {
      return (
        <img
          style={isMobile ? { width: "95vw" } : { height: "707px" }}
          className="opacity-100 transform-fix max-w-none"
          src={animationSrc}
          alt=""
          draggable="false"
          id="animation"
        />
      )
    }
    return null
  }

  const determineSize = () => {
    let size = 1

    if (open) {
      let startingSize = 1
      let gifHeight = 1
      if (isMobile) {
        startingSize = 0.35 * shelfHeight
        gifHeight = ninetyFiveVWWidth * (170.0 / 450.0)
      } else {
        startingSize = 0.28 * viewportHeight
        gifHeight = 707.0 * (170.0 / 450.0)
      }
      // console.log(
      //   `viewportHeight: ${viewportHeight}`,
      //   `95vw: ${ninetyFiveVWWidth}`,
      //   `starting size: ${startingSize}`
      // )
      size = gifHeight / startingSize
    }
    // console.log(`Determined size scale factor: ${size}`)
    return size
  }

  const determineMarginBottom = () => {
    let marginBottom = 0
    if (open) {
      const halfVH = viewportHeight / 2.0
      marginBottom =
        halfVH - (isMobile ? ninetyFiveVWWidth : 707.0) * 0.18888889
    }
    // console.log(`Determined margin bottom: ${marginBottom}`)
    return marginBottom
  }

  return (
    <>
      <Animate
        start={() => ({
          bottom: startBottom,
          left: startLeft,
          size: 1,
          marginBottom: 0.0,
          zIndex: 29,
        })}
        update={() => ({
          bottom: [open ? 0 : startBottom],
          left: [open ? (isMobile ? 47 : 47.9) : startLeft],
          marginBottom: [determineMarginBottom()],
          size: [determineSize()],
          zIndex: [open ? 40 : 29],
          timing: { duration: 700 },
          events: {
            end: () => {
              setVisability(!open)
              setAnimation(open)
              timeout(1).then(() => {
                setAnimationSrc(`${data.animation1.asset.url}`)
              })
              timeout(871).then(() => setFrame(open))
            },
          },
        })}
      >
        {state => {
          const { bottom, left, size, marginBottom, zIndex } = state
          return (
            <button
              className={`${
                open ? "" : "dillon-hover "
              }absolute block origin-bottom ${caseClassName}`}
              style={{
                bottom: `${bottom}%`,
                left: `${left}%`,
                zIndex: `${open ? Math.max(zIndex, 34) : zIndex}`,
                transform: `translateY(-${marginBottom}px) scale(${size}, ${size})`,
                opacity: `${visability ? 1 : 0}`,
                height: `${isMobile ? "35%" : "auto"}`,
              }}
              onClick={() => {
                destroy()
                setAnimationSrc(`${placeholder}`)
                setAnimation2Src(`${placeholder}`)
                timeout(1).then(() => {
                  setOpen(true)
                })
              }}
              type="button"
            >
              {/* height: `${isMobile && isMobileAspect ? "30.8vh" : "28vh"}`, */}
              <img
                src={data.spine.asset.url}
                className="max-w-none md:hover:scale-[1.15] md:transition-transform md:duration-150 dillon-hover"
                style={{ height: `${isMobile ? "100%" : "28vh"}` }}
                draggable={false}
                alt=""
              />
            </button>
          )
        }}
      </Animate>
      {open ? (
        <Div100vh
          className="z-[33] fixed top-0 w-screen left-0 h-full flex justify-center items-center"
          style={{ backgroundColor: "rgba(0,0,0,.7)" }}
          onClick={() => {
            timeout(1).then(() => {
              destroy()
              destroyTimeouts()
            })
          }}
        />
      ) : null}
      {animation ? (
        <Div100vh
          style={{ zIndex: "49", pointerEvents: "none" }}
          className="top-0 left-0 fixed h-full w-full flex justify-center items-center px-8"
        >
          {whatToDisplay()}
        </Div100vh>
      ) : null}

      {open && video ? (
        <Div100vh
          className={`z-50 w-full h-full justify-center items-center fixed top-0 left-0 ${
            open && video && animation2 ? "flex" : "hidden"
          }`}
          style={{ pointerEvents: "none" }}
        >
          <Zoom right>
            <div
              className="video-wrapper overflow-hidden relative"
              style={isMobile ? { width: "63vw" } : { width: "525px" }}
            >
              {theVideo()}
            </div>
          </Zoom>
        </Div100vh>
      ) : null}
    </>
  )
}

Case.propTypes = {
  caseClassName: PropTypes.string,
  data: PropTypes.shape({
    spine: PropTypes.any.isRequired,
    vimeocode: PropTypes.string,
    ytcode: PropTypes.string,
  }).isRequired,
  startBottom: PropTypes.number.isRequired,
  startLeft: PropTypes.number.isRequired,
  shelfHeight: PropTypes.number.isRequired,
  ninetyFiveVWWidth: PropTypes.number.isRequired,
}

Case.defaultProps = {
  caseClassName: "",
}

export default Case

import React from "react"

const YTVideo = ({ code }) => (
  <iframe
    title="Video"
    className="w-full h-full top-0 left-0 absolute"
    style={{ pointerEvents: "auto" }}
    src={`https://www.youtube.com/embed/${code}`}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

export default YTVideo

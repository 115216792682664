import React, { useState, useEffect } from "react"
import Div100Vh from "react-div-100vh"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Shelf from "../components/shelf"
import Loader from "../components/loader"

const IndexPage = () => (
  <Layout old={false}>
    <SEO title="Home" />
    <Div100Vh className="w-full flex items-center justify-center overflow-x-visible overflow-y-hidden md:overflow-x-hidden">
      <Loader duration={5000}>
        <Shelf />
      </Loader>
    </Div100Vh>
  </Layout>
)
export default IndexPage

IndexPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  }),
}

// IndexPage.defaultProps = {
//   loca
// }

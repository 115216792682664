import React from "react"

const VimeoVideo = ({ code }) => (
  <iframe
    title="Video"
    className="w-full h-full top-0 left-0 absolute object-contain object-center"
    style={{ pointerEvents: "auto" }}
    src={`https://player.vimeo.com/video/${code}`}
    frameBorder="0"
    allow="accelerometer; autoplay; picture-in-picture"
    allowFullScreen
  />
)

export default VimeoVideo

import React, { useRef, useState, useLayoutEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Div100vh, { use100vh } from "react-div-100vh"
import MediaQuery, { useMediaQuery } from "react-responsive"
import signGIF from "../images/gifs/sign.gif"
import mobileShelf from "../images/mobileshelf.png"
import desktopShelf from "../images/desktopshelf.png"
import crunchStack from "../images/crunchstack.png"
import crunchStackMobile from "../images/crunchstackmobile.png"
import dillDudsStack from "../images/dilldudsstack.png"
import musicVideosText from "../images/musicvideos.png"
import commercialsText from "../images/commercials.png"
import halfStack from "../images/halfstack.png"
import reelSign from "../images/reelsign.png"
import Case from "./case"
import Reel from "./reel"

const Shelf = () => {
  const [shelfHeight, setShelfHeight] = useState(0)
  const [ninetyFiveVW, setNinetyFiveVW] = useState(0)

  const shelfRef = useRef(null)
  const ninetyFiveVWRef = useRef(null)

  const fullHeight = use100vh()

  useLayoutEffect(() => {
    setShelfHeight(shelfRef.current.clientHeight)
    setNinetyFiveVW(ninetyFiveVWRef.current.clientWidth)
  })

  const tabletAdjustment = useMediaQuery({
    query: "(max-aspect-ratio: 1/1) and (min-width: 799px)",
  })

  const data = useStaticQuery(
    graphql`
      query SanityQuery {
        takis: sanityProject(name: { eq: "Takis" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        braindead: sanityProject(name: { eq: "Brain Dead" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        cry: sanityProject(name: { eq: "Cry In The Mirror" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        loveback: sanityProject(name: { eq: "Love Back" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        monday: sanityProject(name: { eq: "Monday" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        nowornever: sanityProject(name: { eq: "Now or Never" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        slowdrag: sanityProject(name: { eq: "Slow Drag" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        lotusinn: sanityProject(name: { eq: "Lotus Inn" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        wishmeluck: sanityProject(name: { eq: "Wish Me Luck" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        nobody: sanityProject(name: { eq: "Nobody Gets Me (Like You)" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        ok: sanityProject(name: { eq: "OK" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        scrawny: sanityProject(name: { eq: "Scrawny" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        heybabyhey: sanityProject(name: { eq: "Hey Baby Hey" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        shadows: sanityProject(name: { eq: "Shadows" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        humanmade: sanityProject(name: { eq: "Human Made" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        socco: sanityProject(name: { eq: "Groovy, Comfy" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
        oldspice: sanityProject(name: { eq: "Smell Ya Later" }) {
          animation1 {
            asset {
              url
            }
          }
          coverframe {
            asset {
              url
            }
          }
          ytcode
          vimeocode
          id
          name
          spine {
            asset {
              url
            }
          }
          poster {
            asset {
              gatsbyImageData
            }
          }
          animation2 {
            asset {
              url
            }
          }
          finalframe {
            asset {
              url
            }
          }
        }
      }
    `
  )

  return (
    <>
      <div
        className="invisible opacity-0 absolute h-0"
        style={{ zIndex: "-1", width: "95vw" }}
        ref={ninetyFiveVWRef}
      />
      <MediaQuery maxWidth={799}>
        <Div100vh
          className="h-full relative overflow-y-hidden overflow-x-visible"
          style={{
            width: fullHeight ? `${0.5612 * fullHeight}px` : "56.12vh",
            maxWidth: "100vw",
          }}
        >
          <div
            className="w-full absolute bottom-0 left-0 overflow-x-visible"
            style={{
              height: fullHeight
                ? `${fullHeight - 75}px`
                : "calc(100vh - 75px)",
              maxHeight: "calc(169.5vw - 75px)",
            }}
          >
            <div
              ref={shelfRef}
              className="h-full w-full relative flex flex-col justify-start items-center overflow-x-visible"
            >
              <img
                src={signGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                className="absolute w-5/6 z-20 pointer-events-none"
                style={{
                  bottom: "87.5%",
                }}
                draggable={false}
              />
              {/* <img
                src={crunchStackMobile}
                alt="Boxes of Candy"
                className="absolute z-20 left-8 pointer-events-none"
                style={{ height: "20vh", bottom: "5%" }}
                draggable={false}
              /> */}
              <img
                src={halfStack}
                alt="Boxes of Candy"
                className="absolute z-20 right-10 pointer-events-none"
                style={{ height: "13%", bottom: "5%" }}
                draggable={false}
              />
              <Reel
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                caseClassName="bottom-0"
                startBottom={17.5}
                startLeft={49}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.cry}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={22.65}
                alt=""
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.loveback}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={28.35}
                alt=""
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.monday}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={33.9}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.nowornever}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={39.45}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.slowdrag}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={44.95}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.lotusinn}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={50.6}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.wishmeluck}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={56}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.nobody}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={61.5}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.ok}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={67.05}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.scrawny}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={72.6}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.heybabyhey}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={78.12}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.shadows}
                caseClassName="bottom-0"
                startBottom={48.3}
                startLeft={83.67}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.takis}
                caseClassName="bottom-0"
                startBottom={5}
                startLeft={18}
                alt=""
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.braindead}
                caseClassName="bottom-0"
                startBottom={5}
                startLeft={23.4}
                alt=""
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.humanmade}
                caseClassName="bottom-0"
                startBottom={5}
                startLeft={28.9}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.socco}
                caseClassName="bottom-0"
                startBottom={5}
                startLeft={34.37}
              />
              <Case
                shelfHeight={shelfHeight}
                ninetyFiveVWWidth={ninetyFiveVW}
                data={data.oldspice}
                caseClassName="bottom-0"
                startBottom={5}
                startLeft={39.9}
              />
              <Link
                to="concessions"
                className="absolute z-[29] left-0 postit-hover"
                style={{ bottom: "42%" }}
              >
                <StaticImage
                  src="../images/concessions.png"
                  height={35}
                  quality={100}
                  alt="Go to Concessions Page"
                  draggable="false"
                />
              </Link>
              <Link
                to="posters"
                className="absolute z-[29] right-0 postit-hover"
                style={{ bottom: "42%" }}
              >
                <StaticImage
                  src="../images/posters.png"
                  height={35}
                  quality={100}
                  alt="Go to Posters Page"
                  draggable="false"
                />
              </Link>
              <Link
                to="contact"
                className="absolute z-20 right-0 bottom-1 postit-hover"
              >
                <StaticImage
                  src="../images/contact.png"
                  height={35}
                  quality={100}
                  alt="Go to Contact Page"
                  draggable="false"
                />
              </Link>
              <img
                src={commercialsText}
                className="absolute z-20 mx-auto"
                style={{ height: "4%", bottom: "0.5%" }}
                alt=""
                draggable="false"
              />
              <img
                src={musicVideosText}
                className="absolute z-20"
                style={{
                  height: "3.9%",
                  bottom: "43.55%",
                  right: "25.47%",
                }}
                alt=""
                draggable={false}
              />
              <img
                src={mobileShelf}
                className="w-full absolute bottom-0 px-6 pointer-events-none"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </Div100vh>
      </MediaQuery>
      <MediaQuery minWidth={800}>
        <Div100vh
          className="h-full flex flex-col justify-start items-center relative"
          style={{
            width: "73.18vh",
            maxWidth: "95%",
          }}
          ref={shelfRef}
        >
          <img
            src={signGIF}
            alt="Movie Theatre Sign Reading Dillon Dowdell"
            className="absolute z-20 top-1"
            style={{ width: "45vh" }}
            draggable={false}
          />
          {/* <img
            src={dvdPlayer}
            alt="DVD Player"
            className="absolute z-20 left-5 sm:left-4s md:hover:scale-105 md:transition-transform md:duration-150"
            style={{ height: "18vh", bottom: "50.2%" }}
            draggable={false}
          /> */}
          <img
            src={reelSign}
            alt=""
            className="fixed z-20 bottom-[65%] right-1/2 transform pointer-events-none -translate-x-[28.8vh]"
            style={{ width: "28vh" }}
            draggable={false}
          />
          <Reel
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            caseClassName="bottom-0"
            startBottom={63}
            startLeft={3}
          />
          <img
            src={halfStack}
            alt="Boxes of Candy"
            className="absolute z-[15] left-6 sm:left-[2.35rem] pointer-events-none"
            style={{ height: "13vh", bottom: "50.2%" }}
            draggable={false}
          />
          <img
            src={crunchStack}
            alt="Boxes of Candy"
            className="absolute z-20 left-2 sm:left-12 pointer-events-none"
            style={{ height: "25vh", bottom: "6%" }}
            draggable={false}
          />
          <img
            src={dillDudsStack}
            alt="Boxes of Candy"
            className="absolute z-20 right-2 sm:right-12 pointer-events-none"
            style={{ height: "25vh", bottom: "6%" }}
            draggable={false}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.cry}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={42.45}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.loveback}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={46.3}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.monday}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={50.1}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.nowornever}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={53.9}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.slowdrag}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={57.7}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.lotusinn}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={61.4}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.wishmeluck}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={65.1}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.nobody}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={68.9}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.ok}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={72.7}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.scrawny}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={76.5}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.heybabyhey}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={80.3}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.shadows}
            caseClassName="bottom-0"
            startBottom={50}
            startLeft={84.1}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.takis}
            caseClassName="bottom-0"
            startBottom={6}
            startLeft={40.1}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.braindead}
            caseClassName="bottom-0"
            startBottom={6}
            startLeft={43.9}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.humanmade}
            caseClassName="bottom-0"
            startBottom={6}
            startLeft={47.7}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.socco}
            caseClassName="bottom-0"
            startBottom={6}
            startLeft={51.5}
          />
          <Case
            shelfHeight={shelfHeight}
            ninetyFiveVWWidth={ninetyFiveVW}
            data={data.oldspice}
            caseClassName="bottom-0"
            startBottom={6}
            startLeft={55.3}
          />
          <Link
            to="concessions"
            className="absolute z-[29] postit-hover"
            style={{
              bottom: tabletAdjustment ? "41%" : "39.5%",
              left: tabletAdjustment ? "1%" : "-5rem",
            }}
          >
            <StaticImage
              src="../images/concessions.png"
              height={80}
              quality={100}
              alt="Go to Concessions Page"
              draggable="false"
            />
          </Link>
          <Link
            to="posters"
            className="absolute z-[29] postit-hover"
            style={{
              bottom: tabletAdjustment ? "41%" : "39.5%",
              right: tabletAdjustment ? "1%" : "-5rem",
            }}
          >
            <StaticImage
              src="../images/posters.png"
              height={80}
              quality={100}
              alt="Go to Posters Page"
              draggable="false"
            />
          </Link>
          <Link
            to="contact"
            className="absolute z-20  bottom-1 postit-hover"
            style={{
              right: tabletAdjustment ? "1%" : "-5rem",
            }}
          >
            <StaticImage
              src="../images/contact.png"
              height={80}
              quality={100}
              alt="Go to Contact Page"
              draggable="false"
            />
          </Link>
          <span
            className="absolute z-20 mx-auto"
            style={{
              bottom: "1.3%",
            }}
          >
            <StaticImage
              src="../images/commercials.png"
              height={35}
              quality={100}
              alt=""
              draggable="false"
            />
          </span>
          <span
            className="absolute z-20"
            style={{
              bottom: "45.15%",
              right: tabletAdjustment ? "10rem" : "7rem",
            }}
          >
            <StaticImage
              src="../images/musicvideos.png"
              height={35}
              quality={100}
              alt=""
              draggable="false"
            />
          </span>
          {/* md:h-5/6 md:object-contain */}
          <img
            src={desktopShelf}
            className="w-full absolute bottom-0"
            alt=""
            draggable="false"
          />
        </Div100vh>
      </MediaQuery>
    </>
  )
}

export default Shelf

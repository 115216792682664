import PropTypes from "prop-types"
import React, { useState, useLayoutEffect } from "react"
import Div100vh, { use100vh } from "react-div-100vh"
import { Animate } from "react-move"
import Zoom from "react-reveal-iframe/Zoom"
import RubberBand from "react-reveal-iframe/RubberBand"
import { useMediaQuery } from "react-responsive"
import VimeoVideo from "./vimeovideo"
import dvdPlayer from "../images/gifs/dvdplayer.gif"
import dvdPlayerOff from "../images/dvdplayeroff.png"

const Reel = ({
  caseClassName,
  startBottom,
  startLeft,
  shelfHeight,
  ninetyFiveVWWidth,
}) => {
  const [video, setVideo] = useState(false)
  const [open, setOpen] = useState(false)
  const [visability, setVisability] = useState(true)
  const [viewportHeight, setViewportHeight] = useState(0)
  const calculatedViewportHeight = use100vh()
  useLayoutEffect(() => {
    setViewportHeight(calculatedViewportHeight)
  })

  const isMobile = useMediaQuery({
    query: "(max-width: 800px)",
  })

  const destroyTimeouts = () => {
    const highestTimeoutId = setTimeout(";")
    for (let i = 0; i < highestTimeoutId; i += 1) {
      clearTimeout(i)
    }
  }

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay))
  }
  const destroy = () => {
    timeout(1).then(() => {
      setVideo(false)
      setOpen(false)
    })
    timeout(2).then(() => destroyTimeouts())
    setVisability(true)
  }

  const theVideo = () => (
    <VimeoVideo
      className={`${open && video ? "block" : "hidden"} w-full h-full`}
      code="799709056"
    />
  )

  const determineSize = () => {
    let size = 1

    if (open) {
      let startingSize = 1
      let gifHeight = 1
      if (isMobile) {
        startingSize = 0.35 * shelfHeight
        gifHeight = ninetyFiveVWWidth * (170.0 / 450.0)
      } else {
        startingSize = 0.28 * viewportHeight
        gifHeight = 707.0 * (170.0 / 450.0)
      }
      // console.log(
      //   `viewportHeight: ${viewportHeight}`,
      //   `95vw: ${ninetyFiveVWWidth}`,
      //   `starting size: ${startingSize}`
      // )
      size = 10
    }
    // console.log(`Determined size scale factor: ${size}`)
    return size
  }

  const determineMarginBottom = () => {
    let marginBottom = 0
    if (open) {
      const halfVH = viewportHeight / 2.0
      marginBottom =
        halfVH - (isMobile ? ninetyFiveVWWidth : 707.0) * 0.18888889
    }
    // console.log(`Determined margin bottom: ${marginBottom}`)
    return marginBottom
  }

  return (
    <>
      <Animate
        start={() => ({
          bottom: startBottom,
          left: startLeft,
          size: 1,
          marginBottom: 0.0,
          zIndex: 29,
          mobileTX: 0,
        })}
        update={() => ({
          bottom: [open ? 0 : startBottom],
          left: [open ? (isMobile ? 50 : 30) : startLeft],
          marginBottom: [open ? (isMobile ? -159.5 : -75) : 0.0],
          size: [open ? (isMobile ? 3.1 : 6.6) : 1],
          mobileTX: [open ? (isMobile ? 55 : 0) : 0],
          zIndex: 29,
          timing: { duration: isMobile ? 300 : 600 },
          events: {
            start: () => {
              timeout(500).then(() => {
                setVideo(true)
              })
            },
            end: () => {
              setVisability(!open)
            },
          },
        })}
      >
        {state => {
          const { bottom, left, size, marginBottom, mobileTX, zIndex } = state
          return (
            <button
              className={`${isMobile ? "origin-right" : "origin-center"} ${
                isMobile && open ? "fixed" : "absolute"
              } block ${caseClassName} transition-opacity duration-300`}
              style={{
                bottom: `${bottom}%`,
                left: `${left}%`,
                zIndex: `${open ? 30 : 31}`,
                transform: `translateX(${mobileTX}%) translateY(${marginBottom}%) scale(${size}, ${size})`,
                height: `${isMobile ? "17.8%" : "auto"}`,
                touchAction: `${open ? "none" : "auto"}`,
                pointerEvents: `${open ? "none" : "auto"}`,
              }}
              onClick={() => {
                destroy()
                timeout(1).then(() => {
                  setOpen(true)
                })
              }}
              type="button"
            >
              {/* height: `${isMobile && isMobileAspect ? "30.8vh" : "28vh"}`, */}
              <img
                src={dvdPlayer}
                className="max-w-none md:hover:scale-[1.15] md:transition-transform md:duration-150"
                style={{
                  height: `${isMobile ? "100%" : "18vh"}`,
                  display: `${visability ? "block" : "none"}`,
                }}
                draggable={false}
                alt=""
              />
              <img
                src={dvdPlayerOff}
                className="max-w-none md:transition-transform md:duration-150"
                style={{
                  height: `${isMobile ? "100%" : "18vh"}`,
                  display: `${visability ? "none" : "block"}`,
                }}
                draggable={false}
                alt=""
              />
            </button>
          )
        }}
      </Animate>

      {open ? (
        <>
          <Animate
            start={() => ({
              opacity: 0.0,
            })}
            update={() => ({
              opacity: [open ? 0.8 : 0.0],
              timing: { duration: 200 },
            })}
          >
            {state => {
              const { opacity } = state
              return (
                <Div100vh
                  className="z-[31] fixed top-0 w-screen left-0 h-full flex justify-center items-center"
                  style={{ backgroundColor: `rgba(0,0,0,${opacity})` }}
                  onClick={() => {
                    timeout(1).then(() => destroy())
                  }}
                />
              )
            }}
          </Animate>
          <Div100vh
            className={`z-50 w-full h-full justify-center items-center fixed top-0 left-0 ${
              open ? "flex" : "hidden"
            }`}
            style={{ pointerEvents: "none" }}
          >
            <Zoom when={video} duration={500}>
              {/* <RubberBand duration={1000}> */}
              <div className="video-wrapper overflow-hidden relative w-full h-full">
                <div className="w-full h-full flex items-center justify-center">
                  <div
                    className="relative"
                    style={{
                      width: isMobile ? "90vw" : "100vw",
                      height: isMobile ? "25vh" : "55vh",
                    }}
                  >
                    {theVideo()}
                  </div>
                </div>
              </div>
              {/* </RubberBand> */}
              {/* < </RubberBand> */}
            </Zoom>
          </Div100vh>
        </>
      ) : null}
    </>
  )
}

Reel.propTypes = {
  caseClassName: PropTypes.string,
  startBottom: PropTypes.number.isRequired,
  startLeft: PropTypes.number.isRequired,
  shelfHeight: PropTypes.number.isRequired,
  ninetyFiveVWWidth: PropTypes.number.isRequired,
}

Reel.defaultProps = {
  caseClassName: "",
}

export default Reel
